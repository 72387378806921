import { instanceId } from "../../config";

export class TransferClient {
    constructor() {
        if (TransferClient._instance) {
            throw new Error("Singleton classes can't be instantiated more than once.")
        }
    }

    // Agent Transfers
    async getAgents(token) {
        let uri = process.env.REACT_APP_TRANSFER_URI.concat('/agents').concat('/').concat(instanceId)
        let request = {
            method: 'GET',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };

    async putAgentTransfer(contact, token) {
        let uri = process.env.REACT_APP_TRANSFER_URI.concat('/agenttransfer').concat('/').concat(instanceId)
        let request = {
            method: 'PUT',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token) },
            body: JSON.stringify(contact)
        }
        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };

    //Queue Transfers
    async getQueues(token) {
        let uri = process.env.REACT_APP_TRANSFER_URI.concat('/queues').concat('/').concat(instanceId)
        let request = {
            method: 'GET',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token)},
        }
        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };

    async putQueueTransfer(contact, token) {
        let uri = process.env.REACT_APP_TRANSFER_URI.concat('/queuetransfer').concat('/').concat(instanceId)
        let request = {
            method: 'PUT',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token) },
            body: JSON.stringify(contact)
        }

        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };
};