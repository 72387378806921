import Queue from "./components/Queue";
import ContactAttributes from "./components//ContactAttributes/ContactAttributes";
import Lex from './components/Lex';
import AgentBoard from './components/AgentBoard'
import NfmDss from './components/NfmDss'
import CallDisposition from './components/CallDisposition/CallDisposition'
import Sms from './components/Sms/Sms'
import AgentTransfer from './components/AgentTransfer/AgentTransfer'
import AgentNotes from "./components/AgentNotes/AgentNotes";

export const widgetmap = {
    "Queue" : Queue,
    "ContactAttributes": ContactAttributes,
    "Lex" : Lex,
    "AgentBoard": AgentBoard,
    "NfmDss": NfmDss,
    "CallDisposition": CallDisposition,
    "Sms": Sms,
    "AgentTransfer": AgentTransfer,
    "AgentNotes": AgentNotes
  };