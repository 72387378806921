export default {
    contact: {},
    contactId: {},
    agent: {},
    state: {
        incoming: false,
        accepted: false,
        connected: false,
        callEnded: false,
        chatEnded: false,
        ticketSubmitted: false
    },
    transcriptHtml: '',
    visitedTabs: {},

    clear: function () {
        this.contact = {};
        this.agent = {};
        this.state = {
            incoming: false,
            accepted: false,
            connected: false,
            ticketSubmitted: false
        };
        this.user = null;
        this.dialOut = null;
        // console.log(logStamp('cleared session data'), this);
      }
    }
