import React, { useCallback, useState, useContext } from "react";
import Modal from '@mui/material/Modal';
import IconButton from "@material-ui/core/IconButton";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import LinkOffSharpIcon from '@mui/icons-material/LinkOffSharp';
import CloseIcon from '@mui/icons-material/Close';
import MainTable from "../common/MainTable";
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {AgentContext} from '../AgentContextProvider'
import { useConnected } from '../../hooks.js'
import './agentlist.css';
import { minWidth } from "@mui/system";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';


const style = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    margin: '5px',
    backgroundColor: 'red',
    fontWeight: 'bold',
    borderRadius: '5px'
  },
  body: {
    padding: 0,
    height: '80%',
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  },
  modalHeader:{
    backgroundColor: '#4CC6FF',
    color: 'black',
    borderRadius: '5px'
  }
};




export default function AgentList(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const state = useContext(AgentContext);
  const currentUsername = state.username;
  const agentRows = props.agentRows
    const agentRowsOffline = agentRows.filter(agentRow => agentRow.summaryStatusName != 'Offline')
  const agentRowsFiltered = agentRowsOffline.filter(agentRow => agentRow.routingProfile === state.routingProfile)
  
  const columns = [  
    { field: 'lastName', headerName: 'Last Name', editable: false, flex: 1 },
    { field: 'firstName', headerName: 'First Name', editable: false, flex: 1 },
    { field: 'username', headerName: 'Username', editable: false, flex: 1 },
    { field: 'summaryStatusName', 
      headerName: 'Current Status', 
      flex: 1,
      renderCell: params => {
        if (params.row.channel === 'CHAT') {
            return <div><ChatSharpIcon /></div>;
        }
        if (params.row.channel === 'VOICE') {
            return <div><LocalPhoneSharpIcon /></div>;
        }
        return <div>{params.row.summaryStatusName}</div>
      }
            }
  ]
  return (
    <div>
 
      <IconButton aria-label="save" onClick={handleOpen}>
        <PeopleAltIcon />
      </IconButton>
     

      
      <Modal
        style={style}
        open={open}
        onClose={handleClose}
      >
         <Paper style={style.body}>
         <AppBar style={style.modalHeader} position="static">
        <Toolbar>

  
  
          <Typography variant="h6" 
            component="div" sx={{ flexGrow: 1 }}>
            Agent Status
          </Typography>


      <div className='closeIcon'> 
      <IconButton aria-label="save" onClick={handleClose}>
      <CloseIcon />
      </IconButton>
      </div>
        </Toolbar>
      </AppBar>
      <div>
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    columns={columns}
                    rows={agentRowsFiltered}
                    slots={{ toolbar: GridToolbar }}
                    disableColumnSelector={true}
                    exportIcon={false}
                    autoPageSize={true}
                    slotProps={{
                        toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>
        </div>
      </Paper>
      </Modal>
    
     
    </div>
  );

}