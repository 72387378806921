import {ApiCore} from '../api/utilities/core'
const url = 'loadccpconfig'
const apiCCPConfig = new ApiCore({
    get: false,
    put: true,
    url: url
  });



  
export {apiCCPConfig};