import React, { memo, useCallback, useState, useEffect, useContext, useRef } from "react";

import { updateSapId, refreshSms } from "../updateSapId.js";
import { resetFields } from "../updateSapId.js";
import subscribeToContactEvents from '../contactEvents.js';


import './sms.css'

const Sms = () => {
  useEffect(()=>{

    window.connect.contact(subscribeToContactEvents);
  },[]);


  function loadTab(evt,chatNum) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
      tablinks[i].disabled = true;
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(chatNum).style.display = "block";
    evt.currentTarget.className += " active";
    let elButton=chatNum+"button";
    document.getElementById(elButton).disabled = false;
  }




  function disableFields() {

    document.getElementById("sap_id").disabled = true;
    document.getElementById("contact_fname").disabled = true;
    //document.getElementById("contact_fname").style.borderColor = "black";
    document.getElementById("contact_lname").disabled = true;
    //document.getElementById("contact_lname").style.borderColor = "black";
    document.getElementById("contact_email").disabled = true;
    //document.getElementById("contact_email").style.borderColor = "black";
    document.getElementById("editbtn").innerHTML = "Edit";
    document.getElementById("cancelbtn").disabled = true;


  }

  function editFields() {

    //If button is 'save'- update SAP ID in DB and reset
    if (document.getElementById("editbtn").innerHTML == "Save") {

      //Check SAP ID value
      if (!updateSapId()) {
        document.getElementById("editbtn").innerHTML = "Edit";

      }
      disableFields();
      return
    }


    //If value is null - return
    let email = document.getElementById("contact_email").value;
    let sap_id = document.getElementById("sap_id").value;
    if (!email || !sap_id) {
      console.log("No active contact.Cannot edit")
      return
    }

    //Enable for edit
    document.getElementById("sap_id").disabled = false;
    document.getElementById("contact_fname").disabled = false;
    document.getElementById("contact_lname").disabled = false;
    document.getElementById("contact_email").disabled = false;
    document.getElementById("editbtn").innerHTML = "Save";
    document.getElementById("cancelbtn").disabled = false;

  }

  return (

    <div className="item contact-attributes">
      <div className="contact-attributes-container">
        <div className="table-header">
          Contact Attributes
        </div>

        <div className="table-content">
          <table id="t01" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "15%" }} id="l_fname" className='not_draggable'>First Name</td>
                <td style={{ width: "35%" }}><input id="contact_fname" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
                <td style={{ width: "15%" }}className='not_draggable'>Subject</td>
                <td style={{ width: "35%" }}><input id="contact_subject" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
              </tr>

              <tr>
                <td style={{ width: "15%" }} id="l_lname" className='not_draggable'>Last Name </td>
                <td style={{ width: "35%" }}><input id="contact_lname" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
                <td style={{ width: "15%" }} className='not_draggable' >Product</td>
                <td style={{ width: "35%" }}><input id="contact_product" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
              </tr>

              <tr>
                <td style={{ width: "15%" }} id="l_email" className='not_draggable'>Email</td>
                <td style={{ width: "35%" }}><input id="contact_email" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
                <td style={{ width: "15%" }}>Category</td>
                <td style={{ width: "35%" }}><input id="category" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
              </tr>

              <tr>
                <td style={{ width: "15%" }} className='not_draggable'>Queue</td>
                <td style={{ width: "35%" }}><input id="contact_queue" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
                <td style={{ width: "15%" }} className='not_draggable'>Applicaton ID </td>
                <td style={{ width: "35%" }}><input id="app_id" type="text" style={{ width: "90%" }} className='not_draggable' disabled /></td>
              </tr>

              <tr>
                <td>SAP ID </td>

                <td>
                  <input id="sap_id" type="text" style={{ width: "50%" }} className='not_draggable' disabled />
                  <button id="editbtn" onClick={() => editFields()}>Edit</button>
                  <button id="cancelbtn" onClick={() => resetFields()} disabled="">Cancel</button>
                </td>

                <td><button id="sap_update" onClick={()=> refreshSms()}>Refresh SMS</button></td>
              </tr>

            </tbody>
          </table>


          <div class="tab">
            <button class="tablinks" id="chatonebutton" onClick={e => loadTab(e,'chatone')} hidden>Chat One</button>
            <button class="tablinks" id="chattwobutton" onClick={e => loadTab(e, 'chattwo')} hidden>Chat Two</button>
            <button class="tablinks" id ="chatthreebutton" onClick={e => loadTab(e, 'chatthree')} hidden>Chat Three</button>
          </div>





          <div id="chatone" class="tabcontent" hidden>
            <iframe id="smsUrl1" src="about:blank" style={{ width: "850px", height: "370px" }} frameBorder="0">
          </iframe>
          </div>

          <div id="chattwo" class="tabcontent" hidden>
            <iframe id="smsUrl2" src="about:blank" style={{ width: "850px", height: "370px" }} frameBorder="0">
          </iframe>
          </div>

          <div id="chatthree" class="tabcontent" hidden>
            <iframe id="smsUrl3" src="about:blank" style={{ width: "850px", height: "370px" }} frameBorder="0">
          </iframe>
          </div>
        </div>

      </div>
    </div>
  )
}



export default memo(Sms);