import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Failure = (props) => {
  const failureOpen = props.failureOpen;
  const setFailureOpen = props.setFailureOpen;

  const handleClose = (event, reason) => {
    setFailureOpen(false)
  };

  return (
    <Snackbar open={failureOpen} autoHideDuration={20000} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
    <Alert onClose={handleClose} severity="success">
      Success!
    </Alert>
  </Snackbar>
  );
}