import React, { memo, useState, useEffect,useContext,useCallback} from "react";
import "amazon-connect-streams";
import session from './session.js';
import '../css/styles-agentboard.css'
import {AgentContext} from './AgentContextProvider'
import {ContactContext} from './ContactContextProvider'
import {api_url, instanceId, slaTime, tz, apireq} from '../config.js'
import {useConnected, useDestroy, useCallCompleted} from '../hooks.js'

import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const AgentBoard = () =>{
const [displayStatus, setDisplayStatus]= useState(null)

const state = useContext(AgentContext)
const contactstate = useContext(ContactContext)
const isLoggedIn = state.isLoggedIn
let routingProfile = state.routingProfile
let username = state.username

let onContact = contactstate.onContact
const agent = session.agent



useEffect(() => {
  //Fires every 30 seconds
  if (!isLoggedIn) return
  if (contactstate.status === ''){
    contactstate.setOnContact(false, agent.getState().name)
  }
let agentstatus = contactstate.status

let stateduration = agent.getStateDuration()
const intervalId = setInterval(()=>{
const storagetime = JSON.parse(localStorage.getItem('AgentStatus')).timestamp
const timenow = new Date().getTime()
const passedtime = timenow - storagetime
if((!storagetime) || (passedtime > 20000) ){
    updateAgentStatus(routingProfile, username, agentstatus,stateduration)
}
},20000)

return () => {clearInterval(intervalId);}

},[displayStatus])



useEffect(() => {
  //Only fires when the site initially renders
  const agent = session.agent

  if (contactstate.status === ''){
    contactstate.setOnContact(false, agent.getState().name)
  }
  let agentstatus = contactstate.status
  let stateduration = agent.getStateDuration()


  const timenow = new Date().getTime()
  if(localStorage.getItem('AgentStatus') == null || localStorage.getItem('AgentStatus').value == '[]' ){
     updateAgentStatus(routingProfile, username, agentstatus, stateduration)
  } else {
            if ((timenow - JSON.parse(localStorage.getItem('AgentStatus')).timestamp) > 20000){
                updateAgentStatus(routingProfile, username, agentstatus,stateduration)
            }
  }


    agent.onStateChange(function(agentstatechange){
        let agentstatus = agentstatechange.newState
        let contactlist = agent.getContacts()

        if (Array.isArray(contactlist) && contactlist.length == 0){
              setContact(false, agentstatus)
            }
    })




},[])


// On Contact Connected
const onConnected = useCallback((c) => {
    let attr = c.getAttributes();
    let agentstatus = c.getType();
    setContact(true, agentstatus, attr)
}, []);
useConnected(onConnected)
//On Contact completed
const onCallCompleted= useCallback((c) =>{
    let agentstatus = agent.getState().name
    setContact(true, agentstatus)
},[])
useCallCompleted(onCallCompleted)


//On Contact Destroyed
const onDestroy = useCallback((c) => {
    let agentstatus = agent.getState().name
    setContact(false, agentstatus, {})
}, []);
useDestroy(onDestroy);


// This sets the contact status of an agent (if agent is not on a contact, agent status is passed in its place and displayed on the board)
const setContact = (val, agentstatus,attributes) =>{
  contactstate.setOnContact(val, agentstatus, attributes)
}



//This API call updates the DB
async function updateAgentStatus(routingProfile, username, agentstate, stateduration){
  let response = await fetch(api_url +'/agentstatus',{
    method: 'PUT',
    body:JSON.stringify({agent: username, routingProfile:routingProfile, state: agentstate, duration: stateduration})
  })

  let result = await response.json()
  const items = await result.map((i) =>{return i})

  const obj = await {val:items, timestamp: new Date().getTime()}
  const updatedstatus= localStorage.setItem("AgentStatus", JSON.stringify(obj))

  setDisplayStatus(JSON.parse(localStorage.getItem('AgentStatus')).val)

}



    return(

      <>
      {displayStatus ?
      <table className='not_draggable'>
              <tbody id="content" className='table'>

                 
                  {displayStatus.map((i)=>{
                    return(
                            <tr>
                            <td key={i.Username} style={{textAlign:'left'}}>{i.Username}</td>
                            <td key={i.AgentStatus}>
                            {(() => {
                              switch (i.AgentStatus) {
                                case "voice": return <PhoneIcon /> ;
                                case "chat":  return <ChatBubbleIcon />;
                                default:      return i.AgentStatus;
                              }
})()}
                            </td>
                            <td key={i.Duration}>{i.Duration}</td>
                            </tr>
                          )

                  })}
              </tbody>
          </table> : <div>Loading...</div>
      }
      </>


  );


  };

  export default memo(AgentBoard);
