import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';


const style = {
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '4px',
    outline: 'none',
    height: 500,
    width: 500,
    margin: 'auto'
  },

  header: {
    backgroundColor: '#4CC6FF',
    color: 'black',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  headerTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  headerLeftIcons: {
    display: 'flex',
    flexDirection: 'row'

  },
  body: {
    padding: '15px'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2%'
  }
};

const Note = ((props) => {



  const openNote = props.openNote;
  const handleNoteClose = props.handleNoteClose;
  const note = props.note;


  return (
    <div>
      <Modal
        open={openNote}
        onClose={handleNoteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={12} sx={style.container} >
          <Box>
            <AppBar position="static" style={style.header}>

              <Box sx={style.headerContainer}>
                <Box sx={style.headerTitle}>
                  <Typography variant="h6">
                    {note.subject}
                  </Typography>
                </Box>

                <Box sx={style.headerRightCloseIcon}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    align
                    onClick={handleNoteClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </AppBar>
          </Box>


          <form >
            <Box sx={style.bodyContainer} className='not_draggable'>
              <TextField
                id='notename'
                label='Subject'
                style={{ margin: '1%' }}
                inputProps={{ style: { color: "black" } }}
                variant="outlined"
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                value={note.subject}
                disabled
              ></TextField>

              <TextField
                label='Description'
                id='notedetails'
                style={{ margin: '1%' }}
                inputProps={{ style: { color: "black" } }}
                variant="outlined"
                multiline rows={10}
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                value={note.description}
                disabled
              ></TextField>

              <TextField
                label='Customer Phone Number'
                id='custphonenumber'
                style={{ margin: '1%' }}
                inputProps={{ style: { color: "black" } }}
                variant="outlined"
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                value={note.callerPhone}
                disabled
              ></TextField>
            {note.url ?
              <Link sx={{fontSize: 20, margin: '1%'}} target="_blank" rel="noopener noreferrer"  href={note.url} underline="hover">
                Download Attachment
              </Link>: null }

            </Box>
          </form>
        </Paper>
      </Modal>
    </div>
  );
});

export default Note