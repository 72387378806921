import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Success = (props) => {
  const successOpen = props.successOpen;
  const setSuccessOpen = props.setSuccessOpen;

  const handleClose = (event, reason) => {
    setSuccessOpen(false)
  };

  return (
    <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
    <Alert onClose={handleClose} severity="success" >
      Success!
    </Alert>
  </Snackbar>
  );
}