import session from './session.js';
import appSettings from './appSettings.js'
import ui from './ui.js';
import {getSmsTabs,postSmsMessage} from './contactEvents.js';
import {api_url,instanceId, smsdomain} from '../config.js'
import { apiSapId } from '../services/models/sapid.js';





//Update SAP ID
export function updateSapId() {

    let smsTabs = getSmsTabs();    
    let currentContact = session.contact;
    //Update the SAP ID in DB
    try {

        
        let email=document.getElementById("contact_email").value;
        let sap_id=document.getElementById("sap_id").value; 
        let fName=document.getElementById("contact_fname").value;
        let lName=document.getElementById("contact_fname").value; 

        //Check for invalid entries from the form
        if (JSON.stringify(currentContact) === '{}'){
            console.log("No active contact. Cannot update")
            return false;
        }

        if(!sap_id || !email || !fName || !lName ){            
            alert("Please ensure FirstName, LastName, Email and SAP ID are populated.")
            console.log("Check values - SAP ID not updated")
            //Reset the fields from contact attributes
            resetFields();
            return false;
         }

         //SAP ID can only be 7 or 8 digit 
         if(isNaN(Number(sap_id)) || (sap_id.length != 7 && sap_id.length != 8)){
             alert("SAP ID has to be 7 or 8 digit number. Retry.")
             resetFields();
             return false;
         }

        //Fname/Lname/Email are  passed from portal
        let req_body={
                     "sapId": document.getElementById("sap_id").value,
                     "Email": email,
                     "FirstName":document.getElementById("contact_fname").value,
                     "LastName": document.getElementById("contact_lname").value,
                     "instanceId": instanceId,
                     "contactId":currentContact.getContactId(),
                     "operation": "update_sap_id"
                    };
        
            console.log(req_body);

            apiSapId.post(req_body)
           

        
        
    }
    catch (err) {
        console.error('attempted to update sap id', err);
    }
    
}

export function refreshSms(){

    let smsTabs = getSmsTabs();    
    let currentContact = session.contact;
    //Update the SAP ID in DB
    try {

        let url= appSettings.apiUpdateSapId;  
        let email=document.getElementById("contact_email").value;
        let sap_id=document.getElementById("sap_id").value; 

        if (JSON.stringify(currentContact) === '{}'){
            console.log("No active contact. Cannot update")
            return false;
        }

        if(!sap_id || !email || sap_id == 'NOT_FOUND' ){

            console.log("Check values- SAP ID not updated")
            return false;
         }

        // Only Post Message to SMS
        postSmsMessage("updateSapId",currentContact); 

        }       
        
        catch (err) {
            console.error('attempted to update sap id', err);
        }
    
}



async function apiPostRequest(url,req_body) {
    const response = await apiSapId.post(req_body)
    //Post Message to SMS
    let currentContact = session.contact;
    updatePostMessage("updateSapId",currentContact,req_body);
  }


/*
postMessage to SMS iFrame after updateSAPID()
Retrieve data from form and pass to postMessage to avoid race condition 
with getContactAttributes()
*/
export function updatePostMessage(eventName,smsContact,req_body){
    // Use PostMessage to pass data to corresponding SMS iFrame tab
    //Get Current contact
    console.log('did it make it here?')
    try{
    let attr = smsContact.getAttributes();
    let queueId=  ((smsContact.getQueue().queueARN).split("/"))[3];
    let smsTabs = getSmsTabs();    
    console.log(req_body)
    let contactMessage= {
        "event":eventName,
        "contactId":req_body.contactId,
        "firstName":req_body.FirstName,
        "lastName":req_body.LastName,
        "email":req_body.Email,
        "product":attr['Product'].value,
        "category":attr['Category'].value,
        "serviceNumber": attr['ServiceNumber'].value,
        "subject":attr['Subject'].value,
        "queueName":smsContact.getQueue().name,
        "queueId":queueId,
        "agent":session.agent.getConfiguration().username,
        "sap_id": req_body.sapId
    };
    
    console.log(typeof(contactMessage['sap_id']))
    
    var message = JSON.stringify(contactMessage);
    console.log("PostMessage:" + eventName + message);
    //Post to corresponding SMS element
   
    let index = smsTabs.find(function(element){ 
        return element.contact_id == smsContact.contactId;
    })

    var iframe = document.getElementById(index.iframeId).contentWindow;   
    iframe.postMessage(message,smsdomain);

    return
}catch(e){console.log(e)}
}


export function resetFields(){

    let currentContact = session.contact;
    //Reset the fields from contact attributes
    let attr = currentContact.getAttributes();
    ui.setText("contact_fname",attr['FirstName'].value);
    ui.setText("contact_lname",attr['LastName'].value);
    ui.setText("contact_email",attr['Email'].value);
    ui.setText("sap_id",attr['sapId'].value);
    //Disable fields
    document.getElementById("sap_id").disabled = true;
    document.getElementById("contact_fname").disabled = true;
    document.getElementById("contact_lname").disabled = true;
    document.getElementById("contact_email").disabled = true;
    document.getElementById("cancelbtn").disabled = true;
    document.getElementById("editbtn").innerHTML = "Edit";

    return;
}