import React, { useContext, useState, useEffect } from 'react';
import { AgentContext } from '../AgentContextProvider';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from '@mui/icons-material/ListAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import CreateNote from './CreateNote';
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@mui/icons-material/Close';
import Note from './Note';
import { Failure } from '../Alert/Failure';
import { Success } from '../Alert/Success';
import './note.css';

const style = {
    container: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        border: '1px solid #ccc',
        background: '#fff',
        borderRadius: '4px',
        outline: 'none',
        height: 750,
        width: 1000,
        display: 'flex',
        flexDirection: 'column',
        magin: '1%'
    },

    headerContainer: {
        backgroundColor: '#4CC6FF',
        color: 'black',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'

    },
    headerTitle: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    
    headerRightCloseIcon:{
        marginLeft: 'auto'
     
    },

    headermenuLeftIcons: {
        display: 'flex',
        flex: '1',
    },

    menuBar: {
        backgroundColor: '#E5E4E2',
        color: 'black',
        display: 'flex',
    },
    menuContainer: {
        display: 'flex',
    },
    noteDisplayRight: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly',
        margin: 'auto',
        padding: '1%'
    },
};

const AgentNotes = (props) => {

    const [noteList, setNoteList] = useState([]);
    const state = useContext(AgentContext);
    const isLoggedIn = state.isLoggedIn;
    const agentId = state.id;
    const idToken = state.idToken;
    const [openNote, setOpenNote] = useState(false);
    const [noteDetails, setNoteDetails] = useState({});
    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const unreadEvents = props.unreadEvents;
    const setUnreadEvents = props.setUnreadEvents;
    const taskClient = props.taskClient;
    const transferClient = props.transferClient;
    const handleClose = () => setOpen(false);
    const agentRows = props.agentRows;

    const handleOpen = () => {
        setOpen(true);
        getTasks(agentId);
    }

    const handleNoteOpen = (note) => {
        setNoteDetails(note);
        setOpenNote(true);
        return null
    };

    const handleNoteClose = () => {
        setOpenNote(false);
        setNoteDetails({});
        return null
    };

    const columns = [
        {
            field: 'subject',
            headerName: 'Subject',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1
        },
        {
            field: 'createddate',
            headerName: 'Date',
            type: 'date',

        },
        {
            field: 'view',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (note) => {
                return (
                    <Box sx={style.noteDisplayRight}>
                        <Button size='small' onClick={(e) => handleView(e, note.row.object)} variant='contained' style={{ margin: '2%', height: '32%', width: '100%' }}>Open</Button>
                        <Button size='small' onClick={(e) => handleMark(e, note.row.object)} variant='contained' style={{ margin: '2%', height: '32%', width: '100%' }}>Pending</Button>
                        <Button size='small' onClick={(e) => handleComplete(e, note.row.object)} variant='contained' style={{ margin: '2%', height: '32%', width: '100%' }} disabled={note.row.status === 'PENDING'}>Complete</Button>
                    </Box>)
            }
        }
    ];

    let rows = noteList.map((note, index) => {

        return (
            {
                id: index,
                subject: note.subject,
                status: note.status,
                object: note,
                createddate: new Date(note.createdAt * 1000)
            })
    });

    const handleView = (e, updatedNote) => {
        e.preventDefault();
        if (updatedNote.status == 'PENDING') {
            let inProgressNote = { ...updatedNote, status: 'IN_PROGRESS' }
            taskClient.updateTask(inProgressNote, idToken)
                .then(
                    setNoteList(noteList.map(note => {
                        if (note.id == updatedNote.id) {
                            return inProgressNote
                        } else {
                            return note
                        }
                    })),

                    handleNoteOpen(inProgressNote)
                )
                .catch((e) => {
                    console.error(e)
                    setFailureOpen(false)
                })
        } else {
            handleNoteOpen(updatedNote)
        }
    }

    const handleComplete = (e, updatedNote) => {
        e.preventDefault();
        if (updatedNote.status == 'COMPLETE') { return }
        let completedNote = { ...updatedNote, status: 'COMPLETE' }
        taskClient.updateTask(completedNote, idToken).then(
            setNoteList(noteList.map(note => {
                if (note.id == updatedNote.id) {
                    return completedNote
                } else {
                    return note
                }
            })),

            setSuccessOpen(true)
        )
            .catch((e) => {
                setFailureOpen(true)
            })
    };

    const handleMark = (e, updatedNote) => {
        e.preventDefault();
        if (updatedNote.status == 'PENDING') { return }
        let pendingNote = { ...updatedNote, status: 'PENDING' }

        taskClient.updateTask(pendingNote, idToken).then(

            setNoteList(noteList.map(note => {
                if (note.id == updatedNote.id) {
                    return pendingNote
                } else {
                    return note
                }
            })),
            setSuccessOpen(true)
        )
            .catch((e) => {
                setFailureOpen(true)
            })
    }

    useEffect(() => {
        if (!isLoggedIn) return
        getTasks(agentId)
    }, [])

    useEffect(() => {
        if (unreadEvents == true) {
            getTasks(agentId)
            setUnreadEvents(false)
        }
    }, [unreadEvents])

    async function getTasks(agentId) {
        console.log(agentId)
        let response = await taskClient.getTasks(agentId, idToken)
        let data = await response.json()
        setNoteList(data)
    }

    return (
        <>
            <IconButton aria-label="close" onClick={handleOpen}>
                <Badge color='error' max='9' badgeContent={noteList.filter(note => { return note.status === 'PENDING' }).length}>
                <ListAltIcon size='large' />
                </Badge>
            </IconButton>
          

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={12} sx={style.container} >
                        <AppBar position="static" style={style.headerContainer}>
                           <Box sx={style.headerTitle}>
                                <Typography variant="h6">
                                    Tasks
                                </Typography>
                            </Box>
                            <Box sx={style.headerRightCloseIcon}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="close"
                                    align
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </AppBar>
                    <AppBar position='static' sx={style.menuBar}>
                        <Box sx={style.menuContainer}>
                            <Box sx={style.headermenuLeftIcons}>
                                <CreateNote taskClient={taskClient} transferClient={transferClient} agentRows={agentRows} />
                                <IconButton aria-label="refresh" size="large" onClick={() => getTasks(agentId)}>
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </AppBar>
                    <Box sx={{ height: '100%' }}>
                        <DataGrid
                            height={'100%'}
                            autoPageSize={true}
                            rows={rows}
                            rowHeight={108}
                            columns={columns}
                            experimentalFeatures={{ newEditingApi: true }}
                            getRowClassName={(params) => {
                                return params.row.status === 'PENDING' ? 'pending' : "";
                            }}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'createddate', sort: 'desc' }],
                                }
                            }}
                        />
                    </Box>


                    {openNote ? <Note handleView={handleView} note={noteDetails} handleNoteOpen={handleNoteOpen} handleNoteClose={handleNoteClose} openNote={openNote} /> : null}

                    <Failure failureOpen={failureOpen} setFailureOpen={setFailureOpen} />
                    <Success successOpen={successOpen} setSuccessOpen={setSuccessOpen} />
                </Paper>
            </Modal>
        </>



    )





}

export default AgentNotes