import React, { memo, useCallback, useState, useEffect, useContext, useRef } from "react";
import "amazon-connect-streams";
import IconButton from "@material-ui/core/IconButton";
import './queuetransfer.css'
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import { AgentContext } from '../AgentContextProvider'
import { ContactContext } from '../ContactContextProvider'
import { genLogger } from "../../lib";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const QueueTransfer = (props) => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchRows, setSearchRows] = useState([]);
    const transferClient = props.transferClient;
    const connect = window.connect;
    const contactState = useContext(ContactContext);
    const state = useContext(AgentContext);
    const idToken = state.idToken;
    const currentAgent = new connect.Agent();
    let queueRows = props.queueRows;
   const [sortModel, setSortModel] = React.useState([
        {
          field: 'objectName',
          sort: 'asc',
        },
      ]);
    const columns = [
        { field: 'objectName', headerName: 'Queue', editable: false, flex: 1 },
        {
            field: 'action',
            headerName: 'Transfer Call',
            flex: 1,
            renderCell: (params) => (
                <IconButton aria-label="transfer call" color="primary" onClick={() => {handleTransferClick(params.row.id);}}>
                    <TransferCallIcon />
                </IconButton>
            )
        }
    ];
    const handleTransferClick = (id) => {
        const logger = genLogger("handleQueueTransferClick");
        try {
            //save the transfer info to DDB
            //get the current contactID
            const currentcontactId = currentAgent.getContacts(connect.ContactType.VOICE)[0].contactId;
            const currentTimestamp = Math.round(Date.now() / 1000 + 259200);
            let contact = { 'contactId': currentcontactId, 'xferQueueArn': id, 'transExpiryTTL': currentTimestamp }
            transferClient.putQueueTransfer(contact, idToken)
                .then(data => console.log("successfully wrote queue transfer data", data))
                .catch(error => console.error("error writing queue transfer data", error));

            //initiate the transfer
            const dummyTransferArn = process.env.REACT_APP_DUMMY_QUEUE_TRANSFER_ENDPOINT_ARN;
            const endpoint = new connect.Endpoint();
            endpoint.endpointARN = dummyTransferArn;
            endpoint.endpointId = dummyTransferArn;     //quick connect id is always same as the ARN
            endpoint.type = connect.EndpointType.QUEUE;
            endpoint.name = process.env.REACT_APP_DUMMY_QUEUE_QUICK_CONNECT_NAME;

            currentAgent.getContacts(connect.ContactType.VOICE)[0].addConnection(endpoint, {
                success: function (data) {
                    logger.info("Queue transfer initiated successfully: ", data);
                },
                failure: function (data) {
                    logger.error("Error initiating queue transfer: ", data);
                    window.alert('Unable to initiate call transfer at this time.')
                }
            });
        } catch (error) {
            logger.error("Error in handleQueueTransferClick", error);
        }
    };

    return (
        <div>
            <Box sx={{ height: '600px', width: 1 }}>
                <DataGrid
                    columns={columns}
                    rows={queueRows}
                    slots={{ toolbar: GridToolbar }}
                    disableColumnSelector={true}
                    exportIcon={false}
                    autoPageSize={true}
                    slotProps={{
                        toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                        },
                    }}
sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </Box>
        </div>
    )
}

export default memo(QueueTransfer);
