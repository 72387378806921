
import { AwsClient } from 'aws4fetch'

export const connectName = process.env.REACT_APP_CONNECT_NAME;
export const ccpUrl =  process.env.REACT_APP_CCP_URL;
export const api_url = process.env.REACT_APP_URL;
export const instanceId = process.env.REACT_APP_INSTANCE_ID;
export const loginUrl = process.env.REACT_APP_LOGIN_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const auth_url = process.env.REACT_APP_COGNITO_LOGIN_URL
export const redirectUri = process.env.REACT_APP_REDIRECT_URI;
export const smsdomain = process.env.REACT_APP_SMS_DOMAIN
export const signInUrl = auth_url + '/login?client_id='+clientId+'&response_type=code&scope=openid&redirect_uri='+redirectUri
export const slaTime = 20
export const tz = 'US/Central'

const AWS = require("aws-sdk")


// const queryParams = new URLSearchParams(window.location.search);

export let aws_access_key = ''
export let aws_secret_key = ''
export let aws_session_token = ''
export let apireq = new AwsClient({ accessKeyId: aws_access_key, secretAccessKey: aws_secret_key,sessionToken:aws_session_token, service: 'execute-api',region:'us-east-1'})


