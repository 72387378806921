import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import "amazon-connect-streams";
import Modal from '@mui/material/Modal';
import IconButton from "@material-ui/core/IconButton";
import AgentTransfer from "./AgentTransfer";
import QueueTransfer from "../QueueTransfer/QueueTransfer";
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { AgentContext } from '../AgentContextProvider'
import { ContactContext } from '../ContactContextProvider'
import { useConnected, useCallCompleted, useDestroy } from '../../hooks.js'

const style = {
    top:'10%',
    left:'25%',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '4px',
    outline: 'none',
    height: '650',
    width: '1000',
};

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "650px",
        display: "flex",
        flexDirection: "column"
    },
    header: {
        display: "flex",
        alignItems: "center",
        padding: "0.2rem",
        paddingLeft: "0.4rem",
        backgroundColor: "#4CC6FF",
        fontWeight: "bold",
        borderRadius: "5px"
    },
    body: {
        
    },
});

const columns = [
    { id: 'firstName', label: 'First Name', minWidth: 70 },
    { id: 'lastName', label: 'Last Name', minWidth: 70 },
    { id: 'username', label: 'Username', minWidth: 70 },
    { id: 'summaryStatusName', label: 'Current Status', minWidth: 70 },
    { id: 'id', label: 'Agent ARN', minWidth: 70, display: 'none' },
    { id: 'action', label: 'Action' },
];

const connect = window.connect;

export default function AgentTransferModal(props) {

    const transferClient = props.transferClient;
    const [open, setOpen] = React.useState(false);
    const [selectedTab, setselectedTab] = useState("agent");
    const state = useContext(AgentContext);
    const classes = useStyles();
    const idToken = state.idToken;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleTabChange = (event, newValue) => { setselectedTab(newValue); };
    let contactState = useContext(ContactContext);
    let agentRows = props.agentRows;
    let queueRows = props.queueRows;

    const setPrimaryID = (attrobj) => {
        let newObj = { ...contactState, ...attrobj }
        delete newObj['setOnContact']
        let listArgs = Object.values(newObj).map(val => { return val })
        contactState.setOnContact(...listArgs)
    }


    //Hook for behavior when Contact is connected
    const onConnected = useCallback((c) => {
        try {
            const isCall = c.isSoftphoneCall()
            let currentContact = c.contactId;
            setPrimaryID({ primarycontactid: currentContact, onContact: true, isCall: isCall })
        } catch (e) {

            console.error(">>>>  Error in onConnected: " + e);
        }
    }, []);
    useConnected(onConnected);

    const onCallCompleted = useCallback((c) => {
        let currentContact = c.contactId;
        setPrimaryID({primarycontactid: currentContact, onContact: true, isCall: false });
    }, []);
    useCallCompleted(onCallCompleted);

    const onDestroy = useCallback(() => {
        setPrimaryID({ primarycontactid: '', onContact: false })
    }, []);
    useDestroy(onDestroy);


    return (
        <div>
            <IconButton aria-label="save" onClick={handleOpen} disabled={!contactState.isCall}  >
                <TransferCallIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={style}
            >
                <Paper className={classes.root} elevation={12} >
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Transfer to Agent" value="agent" />
                    <Tab label="Transfer to Queue" value="queue" />
                </Tabs>
                    {selectedTab == "agent" &&
                <div className={classes.body}>
                            <AgentTransfer idToken={idToken} transferClient={transferClient} agentRows={agentRows} />
                </div>
                }
                    {selectedTab == "queue" &&
                <div className={classes.body}>
                            <QueueTransfer idToken={idToken} transferClient={transferClient} queueRows={queueRows} />
                </div>
                }
                </Paper>
            </Modal>
        </div>
    );
}