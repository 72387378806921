import React, { memo, useCallback, useState, useEffect, useContext } from "react";
import "amazon-connect-streams";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from '@mui/icons-material/AccountCircle';
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import { AgentContext } from '../AgentContextProvider'
import { ContactContext } from '../ContactContextProvider'
import { genLogger } from "../../lib";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';


const AgentTransfer = (props) => {
    const { open, handleClose } = props;
    // const [searchResults, setSearchResults] = useState(agentStates);
    const [searchRows, setSearchRows] = useState([]);
    const state = useContext(AgentContext);
    const contactState = useContext(ContactContext);
    const idToken = state.idToken;
    const connect = window.connect;
    const currentAgent = new connect.Agent();
    const transferClient = props.transferClient;
    let agentRows = props.agentRows;
const [sortModel, setSortModel] = React.useState([
        {
          field: 'lastName',
          sort: 'asc',
        },
      ]);


    const columns = [
        { field: 'lastName', headerName: 'Last Name', editable: false, flex: 1 },
        { field: 'firstName', headerName: 'First Name', editable: false, flex: 1 },
        { field: 'username', headerName: 'Username', editable: false, flex: 1 },
        { field: 'summaryStatusName', headerName: 'Current Status', editable: false, flex: 1 },
        {
            field: 'action',
            headerName: 'Transfer Call',
            flex: 1,
            renderCell: (params) => (
                <IconButton aria-label="transfer call" color="primary" onClick={() => { handleTransferClick(params.row.id); }}>
                    <TransferCallIcon />
                </IconButton>
            )
        }

    ];



    const handleTransferClick = (id) => {
        const logger = genLogger("handleTransferClick");
        try {

            //save the transfer info to DDB
            //get the current contactID
            const currentcontactId = currentAgent.getContacts(connect.ContactType.VOICE)[0].contactId;
            const currentTimestamp = Math.round(Date.now() / 1000 + 259200);
            let contact = { 'contactId': currentcontactId, 'xferAgentArn': id, 'transExpiryTTL': currentTimestamp }
            transferClient.putAgentTransfer(contact, idToken)
                .then(data => console.log("successfully wrote agent transfer data", data))
                .catch(error => console.error("error writing agent transfer data", error));

            //initiate the transfer
            const dummyTransferArn = process.env.REACT_APP_DUMMY_AGENT_TRANSFER_ENDPOINT_ARN;
            const endpoint = new connect.Endpoint();
            endpoint.endpointARN = dummyTransferArn;
            endpoint.endpointId = dummyTransferArn;     //quick connect id is always same as the ARN
            endpoint.type = connect.EndpointType.AGENT;
            endpoint.name = "Agent Transfer";

            currentAgent.getContacts(connect.ContactType.VOICE)[0].addConnection(endpoint, {
                success: function (data) {
                    logger.info("Agent transfer initiated successfully: ", data);
                    handleClose();
                },
                failure: function (data) {
                    logger.error("Error initiating agent transfer: ", data);
                    window.alert('Unable to initiate call transfer at this time.')
                }
            });
        } catch (error) {
            logger.error("Error in handleTransferClick", error);
        }
    };

    return (
        <div>
            <Box sx={{ height: '600px', width: 1 }}>
                <DataGrid
                    columns={columns}
                    rows={agentRows}
                    slots={{ toolbar: GridToolbar }}
                    disableColumnSelector={true}
                    exportIcon={false}
                    autoPageSize={true}
                    slotProps={{
                        toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                        },
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </Box>
        </div>
    )
}

export default memo(AgentTransfer);