import {ApiCore} from '../api/utilities/core'
const url = 'dispositions'
let token;
const apiDisposition = new ApiCore({
    get: true,
    put: true,
    url: url,
    token: token
  });



  
export {apiDisposition};