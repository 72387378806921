import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import {AgentContext} from './AgentContextProvider'
import useObserver from "./useObserver/useObserver";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.2rem",
    paddingLeft:"0.4rem",
    backgroundColor:"#4CC6FF",
    fontWeight:"bold",
    borderRadius: "5px"

  },

  body: {
    padding: "0.5rem",


  },

});

const widgetNames = {
  a: 'CCP'
};

export default function Widget(props) {
const state = useContext(AgentContext)
const isLoggedIn = state.isLoggedIn
const widgets = state.widgets
const [height, setHeight] = useState(null);
const [width, setWidth] = useState(null);
let Item = props.component
let id = props.id
let idToken = props.idToken
let groups = props.groups;
const ref = useRef(null)

const sizeCallback = (ref) =>  {
    setHeight(ref[0].contentRect.height);
  setWidth(ref[0].contentRect.width);
  }
useObserver({callback: sizeCallback, element: ref});

useEffect(() => {
  if (!isLoggedIn) return;
  for (let i = 0; i < widgets.length; i++) {
    widgetNames[widgets[i].i] = widgets[i].displayname;
  }


}, [isLoggedIn]);

  const classes = useStyles();

  return (

    <Paper className={classes.root} elevation={12} ref={ref}  >

  {(widgetNames[id] != null) ?
      <div className={classes.header}>
      <Typography variant="h6">
          {widgetNames[id]}
        </Typography>
      </div> : null
    }
      <div className={classes.body}  >
        <Item  idToken={idToken}  height={height} width={width} groups={groups}  />
      </div>

    </Paper>

  );

}
