import React, { useCallback, useContext, useState, useEffect } from 'react';
import debounce from "lodash/debounce";
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Paper from '@mui/material/Paper';
import { AgentContext } from '../AgentContextProvider';
import Button from '@mui/material/Button';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { genLogger } from "../../lib";
import {Autocomplete} from '@mui/material';
import {createFilterOptions} from '@mui/material/Autocomplete';
import { useConnected, useDestroy } from '../../hooks.js';
import { Failure } from '../Alert/Failure';
import { Success } from '../Alert/Success';

const style = {
    container: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        border: '1px solid #ccc',
        background: '#fff',
        borderRadius: '4px',
        outline: 'none',
        height: 600,
        width: 600,
    },

    header: {
        backgroundColor: '#4CC6FF',
        color: 'black',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },

    headerTitle: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    headerLeftIcons: {
        display: 'flex',
        flexDirection: 'row'

    },

    body: {
        padding: '15px'
    },

    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '2%'
    }
};

function getAgentId(agentQueue) {
    try {
        const AGENT_QUEUE_PATTERN = new RegExp('arn:aws:connect:([\\w|\\-]+):(\\w+):instance/([\\w|\\-]+)/agent/([\\w|\\-]+)');
        // group[0] all
        // group[1] region
        // group[2] AWS AccountID
        // group[3] Amazon Connect InstanceID
        // group[4] AgentID
        const groups = AGENT_QUEUE_PATTERN.exec(agentQueue);
        const id = `${groups[4]}`;
        return id
    } catch (e) { console.log(e) }
}

const CreateNote = ((props) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const state = useContext(AgentContext);
    let agentRows = props.agentRows;
    const idToken = state.idToken;
    const [agentName, setAgentName] = useState(null);
    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const taskClient = props.taskClient;
    console.log(agentRows)

    const [note, setNote] = useState({
        "subject": "",
        "description": "",
        "callerPhone": "",
        "destination": "",
        "origin": state.id,
        "toAgent": "",
        "fromAgent": state.username,
    })

    const onConnected = useCallback(
        (c) => {
            try {
                let attr = c.getAttributes()
                setNote({ ...note, "callerPhone": attr['ANI'].value })

            } catch (e) {
                console.error(e);
            }
        },

        []
    );
    useConnected(onConnected);

    const onDestroy = useCallback(() => {
        setNote({
            "subject": "",
            "description": "",
            "callerPhone": "",
            "destination": "",
            "origin": state.id,
            "toAgent": "",
            "fromAgent": state.username,
        })

    }, []);
    useDestroy(onDestroy);

    const handleChange = (event) => {
        switch (event.target.id) {

            case 'notename':
                setNote({ ...note, "subject": event.target.value })
                break;

            case 'notedetails':
                setNote({ ...note, "description": event.target.value })
                break;

            case 'custphonenumber':
                setNote({ ...note, "callerPhone": event.target.value })
                break;
        }
    }

    const createNewNote = (e, row) => {
        e.preventDefault();
        taskClient.putTask(note, idToken)
            .then(
                setNote({
                    "subject": "",
                    "description": "",
                    "callerPhone": "",
                    "destination": "",
                    "origin": state.id,
                    "toAgent": "",
                    "fromAgent": state.username,
                }),

                setAgentName(null),
                setSuccessOpen(true)
                )
            .catch((e) => {
                    setFailureOpen(true)
                console.log(e)
            }
            )
    };

    const handleAgentSelect = (e, row) => {
        setNote({ ...note, "destination": getAgentId(row.id), "toAgent": row.username })
        setAgentName(row.username)
    }


    const filterOptions = createFilterOptions({
        stringify: (option) => option.lastName + option.firstName
      });

    return (
        <div>
            <IconButton aria-label="close" size="large" onClick={handleOpen}>
                <AddCircleOutlineIcon />
            </IconButton>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={12} sx={style.container} >
                    <Box>
                        <AppBar position="static" style={style.header}>
                            <Box sx={style.headerContainer}>
                                <Box sx={style.headerTitle}>
                                    <Typography variant="h6">
                                        Create Task
                                    </Typography>
                                </Box>

                                <Box sx={style.headerRightCloseIcon}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="close"
                                        align
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </AppBar>
                    </Box>
                    <form onSubmit={(e) => { createNewNote(e) }} >
                        <Box sx={style.bodyContainer} className='not_draggable'>
                            <TextField
                                id='notename'
                                label='Subject'
                                style={{ margin: '1%' }}
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                                value={note.subject}
                                required
                                className='not_draggable'
                            ></TextField>

                            <TextField
                                label='Description'
                                id='notedetails'
                                style={{ margin: '1%' }}
                                variant="outlined"
                                multiline rows={10}
                                onChange={(e) => handleChange(e)}
                                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                                value={note.description}
                                required
                                className='not_draggable'
                            ></TextField>

                            <TextField
                                label='Customer Phone Number'
                                id='custphonenumber'
                                style={{ margin: '1%' }}
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                                value={note.callerPhone}
                                className='not_draggable'
                            ></TextField>

                            <Autocomplete
                                id="combo-box-demo"
                                options={agentRows.sort((a,b)=> -b.lastName.localeCompare(a.lastName))}
                                style={{ margin: '1%' }}
                                value={agentName}
                                filterOptions={filterOptions}
                                onChange={
                                    (e, row, reason) => {
                                        if (reason === 'clear') {
                                            setAgentName(null)
                                        } else {
                                            handleAgentSelect(e, row)
                                        }
                                    }
                                }

                                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : ""}
                                renderInput={(params) =>

                                    <TextField {...params}
                                        label="Agent"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 20, backgroundColor: '#FFFFFF' } }}
                                        required
                                        className='not_draggable'
                                    />}

                                renderOption={(props, row) => (
                                    <Box key={row.username} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {row.lastName}, {row.firstName}
                                    </Box>
                                )}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ margin: '5%' }}
                            >
                                Create Task
                            </Button>
                            <Failure failureOpen={failureOpen} setFailureOpen={setFailureOpen} />
                            <Success successOpen={successOpen} setSuccessOpen={setSuccessOpen} />
                        </Box>
                    </form>
                </Paper>
            </Modal>

        </div>
    );
});

export default CreateNote