import React, { useState } from 'react'

export const ContactContext = React.createContext({
  onContact: false,
  status: '',
  setOnContact: () => {},
  attributes: {},
  contactid:[],
  primarycontactid:'',
  isCall: false
})

export const ContactContextProvider = (props) => {

  const setOnContact = (onContact,status, attributes, contactids, primarycontactid, isCall) => {
    setState({...state, onContact: onContact, status: status, attributes: attributes,contactids: contactids, primarycontactid: primarycontactid, isCall:isCall})
  }


  const initState = {
    onContact: false,
    status: '',
    setOnContact:setOnContact,
    attributes:{},
    contactids:[],
    primarycontactid:'',
    isCall: false
  }

  const [state, setState] = useState(initState)

  return (
    <ContactContext.Provider value={state}>
      {props.children}
    </ContactContext.Provider>
  )
}
