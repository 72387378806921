import React, { useState } from 'react'

export const AgentContext = React.createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => { },
  username: '',
  id: '',
  queues: [],
  channels: [],
  attributes: [],
  widgets: [],
  routingProfile: '',
  idToken: ''
})

export const AgentContextProvider = (props) => {

  const setIsLoggedIn = (isLoggedIn, username, id, queues, channels, attributes, widgets, routingProfile, idToken) => {
    setState({ ...state, isLoggedIn: isLoggedIn, username: username, id: id, queues: queues, channels: channels, attributes: attributes, widgets: widgets, routingProfile: routingProfile, idToken: idToken })
  }

  const initState = {
    isLoggedIn: false,
    setIsLoggedIn: setIsLoggedIn,
    username: '',
    id: '',
    queues: [],
    channels: [],
    attributes: [],
    widgets: [],
    routingProfile: '',
    idToken: ''
  };

  const [state, setState] = useState(initState);

  return (
    <AgentContext.Provider value={state}>
      {props.children}
    </AgentContext.Provider>
  )
}