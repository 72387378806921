import React, { memo, useCallback, useContext, useState} from "react";
import TextField from "@material-ui/core/TextField";



const Lex= () =>{
async function getDetails(){

  let response = await fetch('https://sandbox.apis.changehealthcare.com/apip/auth/v2/token',{
    'method': 'POST',
    'headers':{'Content-Type': 'application/json'},
    'body': {
    "client_id": "TAsFudOcI7rUAGdpXe0wjqJwnVRFCC1u",
    "client_secret": "WQX2ElVuWTMP3G87",
    "grant_type": "client_credentials"
}
  })

  let result = await response
  console.log(result)

}

 let response = {
   'message':'Sample Lex message goes here'
 }



  return(
    <>
    {response.message}

    </>

  )
}
export default memo(Lex);
