import {instanceId} from '../../config';
export class TaskClient {
    
    constructor() {
        if (TaskClient._instance) {
            throw new Error("Singleton classes can't be instantiated more than once.")
        }
    }

    async getTasks(agentId, token) {
        let uri = process.env.REACT_APP_TASKS_URI.concat('/task').concat('/').concat('read').concat('/').concat(instanceId).concat('/').concat(agentId);
        let request = {
            method: 'GET',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token) },
        }
        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };

    async putTask(task, token) {
        let uri = process.env.REACT_APP_TASKS_URI.concat('/task').concat('/').concat('create').concat('/').concat(instanceId)
        let request = {
            method: 'PUT',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token) },
            body: JSON.stringify(task)
        }
        const response = await fetch(uri, request)

        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };

    async updateTask(task, token) {
        let uri = process.env.REACT_APP_TASKS_URI.concat('/task').concat('/').concat('update').concat('/').concat(instanceId)
        let request = {
            method: 'PATCH',
            headers: { 'Accept': '*/*', 'Authorization': 'Bearer '.concat(token) },
            body: JSON.stringify(task)
        }
        const response = await fetch(uri, request)
        console.log(response)
        if (!response.ok) {
            console.error(response)
        } else {
            return response
        }
    };
};