import React, { memo, useCallback, useContext, useState, useEffect,useRef} from "react";
import {useConnected, useDestroy} from '../hooks.js'
import { genLogger, LAMBDA_PREFIX, valueToOption } from "../lib.js";
import ui from './ui.js';


const NfmDss = () => {

let attributeRef = useRef()

// Hook for behavior when Contact is connected
const onConnected = useCallback((c) => {
  try {
    const attr = c.getAttributes();
    console.log(attr)
    const url = attr['url'].value
    if (url != null) {window.open(url)};

}
 catch (e) {

  }
}, []);
useConnected(onConnected);









  return(
null
  )



}

export default memo(NfmDss);
