import React from 'react';
import './login.css';
import logo from '../../images/CHC_Logo_Blue_Red.png';





export default function Login() {



  
  return(
<>
    <div className='displayed'>
<img src={logo}  width={150}height={100} alt='logo'  />

    </div>
    <h1 className="loadingLoginPage">
    <span className="let1">l</span>  
    <span className="let2">o</span>  
    <span className="let3">a</span>  
    <span className="let4">d</span>  
    <span className="let5">i</span>  
    <span className="let6">n</span>  
    <span className="let7">g</span>  
  </h1>
</>

  )
}