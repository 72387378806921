import appSettings from './appSettings.js';


function getElementById(id) {
    // // console.log('!document: ', !document);
    if (!document) return;
    return document.getElementById(id);
}

const show = function (id, showAs = 'block') {
    const el = getElementById(id);
    console.log(`making ${id} visible`, el);
    if (el) el.style.display = showAs;
}

const hide = function (id) {
    const el = getElementById(id);
    // // console.log(`hiding ${id}`, el);
    if (el) el.style.display = 'none';
}

const enable = function (id, enabled = true) {
    const el = getElementById(id);
    // // console.log(`enabling ${id}`, el);
    if (el) el.disabled = !enabled;
}

// const resize = function (className, metric) {
//     const el = getElementsByClassName(className);
//     // // console.log(`enabling ${id}`, el);
//     el[0].style.height = "285px"
// }

// Options for the observer (which mutations to observe)
const config = {
    attributes: true,
    childList: true,
    subtree: true
};

// Callback function to execute when mutations are observed
const onMutation = function (mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    for (let mutation of mutationsList) {
        console.log('mutation:', mutation);
        if (mutation.type === 'childList') {
            console.log('A child node has been added or removed.');
        } else if (mutation.type === 'attributes') {
            console.log('The ' + mutation.attributeName + ' attribute was modified.');
        }
    }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(onMutation);

// Later, you can stop observing
// observer.disconnect();

export default {
    show: show,
    hide: hide,
    enable: enable,
    // resize: resize,

    observe: function (containerId) {
        // Start observing the target node for configured mutations
        observer.observe(getElementById(containerId), config);
    },

    getText: function (elementId) {
        const el = getElementById(elementId);
        if (el)
            return el.value || el.innerText;
    },

    setText: function (elementId, text) {
        const el = getElementById(elementId);

        if (el) {
            if (['TEXTAREA', 'INPUT'].includes(el.tagName))
                el.value = text;
            else
                el.innerText = text;
        }
    },

    swapImage: function (imageId, newUrl) {
        const el = getElementById(imageId);
        if (el)
            el.src = newUrl;
    },

    enableButton(buttonId, inputId, minLength) {
        const input = getElementById(inputId);
        if (!input) return;
        input.addEventListener('input', function (e) {
            enable(buttonId, input.value && input.value.length >= minLength);
        });

    },

    onClick: function (elementId, handler) {
        const el = getElementById(elementId);
        if (el)
            el.addEventListener('click', handler);
    },

    onSubmit: function (elementId, handler) {
        const el = getElementById(elementId);
        if (el)
            el.addEventListener('submit', handler);
    },
    focus: function (elementId) {
        const el = getElementById(elementId);
        if (el)
            setTimeout(function () {
                el.focus();
            }, 0);
    },

    createTable: function (id, contactAttr) {
        try {
            let tbody = getElementById(id).getElementsByTagName('tbody')[0];
            Object.values(contactAttr).filter(el => el.name.startsWith(appSettings.contactAttributesPrefix)).forEach((el) => {
                let key = el.name.replace(appSettings.contactAttributesPrefix, '');
                let value = el.value;
                let tr = tbody.insertRow();
                var td1 = tr.insertCell();
                var td2 = tr.insertCell();
                td1.innerHTML = key;
                td2.innerHTML = value;
            })
        } catch (e) {
            console.log(e);
        }
    },
    clearTable: function (id) {

        let tableBody = getElementById(id).getElementsByTagName('tbody')[0];
        tableBody.innerHTML = "";
    },
    clearValue: function (elementId){
        const el = getElementById(elementId);
        if (el) {
                el.value = "";
        }
    },

    addListItem: function (id) {
        let listItems = '';
        appSettings.quickConnects.forEach(item => {
            listItems += `<option value="${item.name}"/>`
        });
        document.getElementById(id).innerHTML = listItems;
    }

}
